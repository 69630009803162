// src/apollo-client.js
import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';

const httpLink = new HttpLink({
  uri: 'https://api.chargeback-calculator.orders.co', // Adjust this to your GraphQL server's URI
  fetch,
  fetchOptions: {
    timeout: 60000,
  },

});

const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
});

export default client;
