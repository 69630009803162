import React, { useEffect, useState } from 'react';
import { useQuery, useLazyQuery } from '@apollo/client';
import { GET_DROPDOWN_OPTIONS, GET_INITIAL_VALUES, GET_CUISINE_TYPES_BY_GROUP } from './queries';
import {
  Container,
  Typography,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Slider,
  CircularProgress,
  ThemeProvider,
  createTheme,
} from '@mui/material';

import { useSearchParams } from 'react-router-dom';



// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2', // A shade of blue for primary actions and elements
    },
    secondary: {
      main: '#dc004e', // A vibrant shade for secondary actions and highlighting important components
    },
    background: {
      default: '#f4f6f8', // A light grey background for minimal eye strain
    },
    text: {
      primary: '#333', // Darker text for better readability
      secondary: '#555', // Slightly lighter text, for less important information
    },
  },
  typography: {
    fontFamily: '"Poppins", "Roboto", "Helvetica", "Arial", sans-serif',
    button: {
      textTransform: 'none', // Buttons with regular casing
    },
  },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: 'outlined', // Default to outlined variants for text fields
        margin: 'normal', // Provide a standard space below the text fields
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: ({ theme }) => ({
          marginBottom: theme.spacing(2), // Consistent margin below form controls
        }),
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: "black", // Use the primary color for sliders
        }),
      },
    },
  },
});

const USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
  round: true,
  roundingIncrement: 1,
  roundingMode: "floor"
});


// // // const interpolateColor = (value, minValue = 0, maxValue = 99) => {
// // //   // Ensure value is within bounds
// // //   if (value < minValue) return `rgb(0,0,0)`;
// // //   const cappedValue = Math.min(Math.max(value, minValue), maxValue);

// // //   // Calculate ratio between 0 and 1
// // //   const ratio = (cappedValue - minValue) / (maxValue - minValue);

// // //   // Define start (very dark red) and end (aggressive maroon) colors
// // //   const startRed = 0, startGreen = 0, startBlue = 0; // Very dark red
// // //   const endRed = 255, endGreen = 0, endBlue = 32; // Aggressive maroon

// // //   // Interpolate between the start and end colors
// // //   const red = Math.ceil((endRed - startRed) * ratio + startRed);
// // //   const green = Math.ceil((endGreen - startGreen) * ratio + startGreen);
// // //   //const blue = Math.ceil((endBlue - startBlue) * ratio + startBlue);
// // //   const blue = Math.ceil((endBlue - startBlue) * ratio + startBlue);
// // //   console.log(">>>> ~ interpolateColor ~ blue:", blue);


// // //   return `rgb(${red}, ${green}, ${blue})`;
// // // };

const FullPageLoader = () => (
  <div style={{
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000, // Ensure it's above everything else
  }}>
    <CircularProgress style={{ color: '#fff' }} />
  </div>
);



const ErrorChargeCalculator = () => {


  const [searchParams] = useSearchParams();
  const showHeading = searchParams.get('heading') !== 'false'; // `true` by default, `false` if heading=false in the URL
  const maxWidth = searchParams.get('mw') || 'sm';
  const showCuisineTypes = searchParams.get('cuisineTypes') !== 'false'; // `true` by default, `false` if cuisineTypes=false in the URL


  const initialValues =
  {
    businessType: '',
    cuisineGroup: '',
    cuisineType: '',
    businessClass: '',
    state: '',
    businessName: '',
    city: '',
    averageNumberOfOrdersPerDay: 0,
    averageTicketSize: 0,
    chargebackRateOrder: 0,
    chargebackRate: 0,
  }
  const [showLoader, setShowLoader] = useState(false);
  const [formValues, setFormValues] = useState(initialValues);

  const { data: dropdownData, loading: dropdownLoading } = useQuery(GET_DROPDOWN_OPTIONS);
  const [getInitialValues, { data: initialValuesData, loading: initialValuesLoading }] = useLazyQuery(GET_INITIAL_VALUES, {
    fetchPolicy: "network-only"
  });
  const [getCuisineTypes, { data: cuisineTypesData, loading: cuisineTypesLoading }] = useLazyQuery(GET_CUISINE_TYPES_BY_GROUP);

  // useEffect(() => {
  //   const sendHeight = () => {
  //     const height = document.documentElement.scrollHeight; // Or any other logic to determine the correct height
  //     window.parent.postMessage({ type: 'SET_HEIGHT', height: height }, '*');
  //     // Consider replacing '*' with the parent app's origin for security
  //   };

  //   sendHeight();

  //   // Optionally: If your content height may change, you might want to call sendHeight
  //   // on specific events or after state updates that affect the height.
  // }, []);
  const postHeight = () => {
    const height = document.documentElement.scrollHeight;
    window.parent.postMessage({ type: 'SET_HEIGHT', height: height }, '*'); // Replace '*' with the parent domain if known for security
  };

  useEffect(() => {
    postHeight(); // Post initial height

    // Set up a mutation observer to watch for changes in the content
    const observer = new MutationObserver(mutations => {
      postHeight();
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
      attributes: false,
    });

    // Listen for resize events
    window.addEventListener('resize', postHeight);

    // Clean up
    return () => {
      observer.disconnect();
      window.removeEventListener('resize', postHeight);
    };
  }, []);

  useEffect(() => {
    if (formValues.cuisineGroup && formValues.cuisineGroup !== "All Classes") {
      getCuisineTypes({
        variables: {
          groupId: formValues.cuisineGroup,
        },
      });
      getInitialValues({
        variables: {
          cuisineGroup: formValues.cuisineGroup,
          businessType: formValues.businessType,
          //cuisineType: formValues.cuisineType,
          cuisineType: '',
          //state: formValues.state,
          state: ''
        }
      });
    }
  }, [formValues.cuisineGroup, formValues.businessType, getCuisineTypes, getInitialValues]);

  const isLoading = dropdownLoading || initialValuesLoading || cuisineTypesLoading;

  useEffect(() => {
    // Show loader immediately if loading, otherwise use a timer for potential delay
    if (isLoading) {
      setShowLoader(true);
    } else {
      const timer = setTimeout(() => setShowLoader(false), 200);
      return () => clearTimeout(timer);
    }
  }, [isLoading]);

  useEffect(() => {
    if (initialValuesData) {
      setFormValues(prev => ({
        ...prev,
        averageNumberOfOrdersPerDay: initialValuesData.analytics.averageNumberOfOrdersPerDay,
        averageTicketSize: initialValuesData.analytics.averageTicketSize,
        chargebackRateOrder: initialValuesData.analytics.chargebackRateOrder,
        chargebackRate: initialValuesData.analytics.chargebackRate,
      }));
    }
  }, [initialValuesData, formValues.cuisineGroup]);


  const handleDropdownChange = (event) => {

    let { name, value } = event.target;
    let updatedFormValues = { ...formValues };

    if (String(value).includes("All ")) {
      value = '';
    }

    if (name === "cuisineGroup") {
      updatedFormValues = initialValues
    }

    updatedFormValues[name] = value;


    setFormValues(updatedFormValues);

    // Now using `updatedFormValues` directly for `getInitialValues`
    getInitialValues({
      variables: {
        cuisineGroup: updatedFormValues.cuisineGroup,
        businessType: updatedFormValues.businessType,
        cuisineType: updatedFormValues.cuisineType,
        state: updatedFormValues.state,
      }
    });
  };


  const handleSliderChange = (name, newValue) => {
    setFormValues({
      ...formValues,
      [name]: newValue,
    });
  };

  useEffect(() => {
    getInitialValues({
      variables: {
        cuisineGroup: 9,
        businessType: formValues.businessType,
        cuisineType: formValues.cuisineType,
        state: formValues.state,
      }
    });
  }, [getInitialValues, formValues.businessType, formValues.cuisineType, formValues.state]);
  // Here's your JSX with minor adjustments for readability and functionality
  // Note: The rest of the component remains largely unchanged
  // Ensure sliders and other inputs are correctly linked to state and update as expected

  // Wrap your return JSX in a <ThemeProvider> to apply the theme
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth={maxWidth} sx={{ padding: theme.spacing(3), backgroundColor: theme.palette.background.default, borderRadius: theme.spacing(1) }}>
        {showLoader && <FullPageLoader />}
        {showHeading && (<Typography variant="h4" gutterBottom marginBottom={12} marginTop={6} color="textPrimary">
          Revenue Recovery Calculator
        </Typography>)}
        <Grid container spacing={2}>

          {/* <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Cuisine Classification</InputLabel>
                <Select
disabled={true}
                  name="cuisineGroup"
                  value={formValues.cuisineGroup || "All Classes"}
                  label="Cuisine Type"
                  onChange={handleDropdownChange}
                  displayEmpty
                  sx={{ textAlign: 'left', '& .MuiSelect-select': { textAlign: 'left' } }}

                >
                  <MenuItem key={"All Classes"} value={"All Classes"}>{"All Classes"}</MenuItem>
                  {dropdownData?.cuisineGroups?.map((type) => (
                    <MenuItem key={type.title} value={type.groupId}>{type.title}</MenuItem>
                  )) || []}
                </Select>
              </FormControl>
            </Grid> */}
          {showCuisineTypes && (<Grid item xs={12} >
            <FormControl fullWidth>
              <InputLabel>Cuisine Type</InputLabel>
              <Select
                disabled={!formValues.cuisineGroup}
                name="cuisineType"
                value={formValues.cuisineType || "All Types"}
                label="Cuisine Type"
                onChange={handleDropdownChange}
                sx={{ textAlign: 'left', '& .MuiSelect-select': { textAlign: 'left' } }}

              >
                <MenuItem key={"All Types"} value={"All Types"}>{"All Types"}</MenuItem>
                {/* Placeholder for dynamic options */}
                {cuisineTypesData?.cuisineTypes?.map((type) => (
                  <MenuItem key={type.title} value={type._id}>{type.title}</MenuItem>
                )) || []}
              </Select>
            </FormControl>
          </Grid>
          )}
          <Grid item xs={12} sm={12}>
            <FormControl fullWidth>
              <InputLabel>State</InputLabel>
              <Select
                // disabled={!formValues.cuisineGroup}
                name="state"
                value={formValues.state || "All States"}
                label="State"
                onChange={handleDropdownChange}
                sx={{ textAlign: 'left', '& .MuiSelect-select': { textAlign: 'left' } }}

              >
                <MenuItem key={"All States"} value={"All States"}>{"All States"}</MenuItem>
                {/* Placeholder for dynamic options */}
                {dropdownData?.states.map((state) => (
                  <MenuItem key={state} value={state}>{state}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>


          <Grid item xs={12}>
            <Typography id="monthly-sales-slider" gutterBottom sx={{ textAlign: "left" }}>
              Daily Orders: <span style={{ "color": "darkorange", "fontWeight": "bold" }}>{formValues?.averageNumberOfOrdersPerDay.toFixed(0) || 0}</span>
            </Typography>

            <Slider
              disabled={!formValues.chargebackRateOrder}
              name="averageNumberOfOrdersPerDay"
              value={formValues?.averageNumberOfOrdersPerDay.toFixed(0) || 0}
              onChange={(e, newValue) => handleSliderChange('averageNumberOfOrdersPerDay', newValue)}
              aria-labelledby="monthly-sales-slider"
              valueLabelDisplay="auto"
              min={0}
              max={100}
            />
            <Typography gutterBottom
              fontSize={"small"} sx={{ textAlign: "left" }}>
              Use the slider to adjust the average number of daily orders to match your data.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography id="average-ticket-size-slider" gutterBottom sx={{ textAlign: "left" }}>
              Average Ticket Size: <span style={{ "color": "darkorange", "fontWeight": "bold" }}>${formValues?.averageTicketSize.toFixed(0) || 0}</span>
            </Typography>

            <Slider
              disabled={!formValues.chargebackRateOrder}
              name="averageTicketSize"
              value={formValues?.averageTicketSize.toFixed(0) || 0}
              onChange={(e, newValue) => handleSliderChange('averageTicketSize', newValue)}
              aria-labelledby="average-ticket-size-slider"
              min={0}
              max={200}
            />
            <Typography gutterBottom
              fontSize={"small"} sx={{ textAlign: "left" }}>
              Move the slider to modify the average ticket size for a more accurate estimate.
            </Typography>
          </Grid>
          <Grid item xs={6} md={6}>
            <Typography id="error-charge-rate-slider" gutterBottom >
              Monthly Orders:<br /><span style={{ "color": "darkorange", "fontWeight": "bold" }}>{(formValues?.averageNumberOfOrdersPerDay * 30).toFixed(0) || 0}</span>
            </Typography>
            <Typography gutterBottom fontSize={"small"}>
              <strong>Disputed Order Rate:<br />{formValues?.chargebackRateOrder.toFixed(2) || 0}%</strong>
            </Typography>

          </Grid>
          <Grid item xs={6} md={6}>
            <Typography id="error-charge-rate-slider" gutterBottom >
              Monthly Sales:<br /><span style={{ "color": "darkorange", "fontWeight": "bold" }}>{USDollar.format(formValues.averageTicketSize * formValues.averageNumberOfOrdersPerDay * 30)}</span>
            </Typography>
            <Typography gutterBottom fontSize={"small"}><strong>Revenue Impact Rate:<br />{formValues?.chargebackRate.toFixed(2) || 0}%</strong>
            </Typography>

          </Grid>
          <Grid item xs={12} variant="paper"
            style={{ "backgroundColor": "#000000", "margin": "30px", "borderRadius": "10px", padding: "16px" }}>
            <Grid item xs={12} sx={{ color: "white !important" }}>
              <Typography id="error-charge-rate-slider" gutterBottom variant='h6' >
                Estimated Chargeback
              </Typography>
              <Typography gutterBottom
                fontSize={"small"}>
                Based on the information provided, the estimated chargeback amount is:
              </Typography>
              <Typography id="error-charge-rate-slider" gutterBottom variant='h5' sx={{ fontWeight: "900", background: 'linear-gradient(to right, rgb(255, 107, 0) 0%, rgb(173, 42, 0) 100%)', WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent" }}
              // style={{ color: interpolateColor(formValues.averageTicketSize * formValues.averageNumberOfOrdersPerDay * 30 * formValues.chargebackRate / 100) }}
              >
                {USDollar.format(formValues.averageTicketSize * formValues.averageNumberOfOrdersPerDay * 30 * formValues.chargebackRate / 100)} -  {USDollar.format(formValues.averageTicketSize * formValues.averageNumberOfOrdersPerDay * 30 * formValues.chargebackRateOrder / 100)}
              </Typography>

            </Grid>
          </Grid>
          {/* <Grid item xs={12}>
            <Typography id="error-charge-rate-slider" gutterBottom>
              Monthly Sales: {USDollar.format(formValues.averageTicketSize * formValues.averageNumberOfOrdersPerDay * 30)}
            </Typography>

          </Grid>
          <Grid item xs={12}>
            <Typography id="error-charge-rate-slider" gutterBottom>
              Disputed order rate: {formValues?.chargebackRateOrder.toFixed(2) || 0}%
            </Typography>
            <Typography id="error-charge-rate-slider" gutterBottom>
              Chargeback revenue impact: {formValues?.chargebackRate.toFixed(2) || 0}%
            </Typography>

          </Grid> */}
          <Grid item xs={12} >
            <Typography gutterBottom
              align='left'
              fontSize={"small"}>
              <b>Disclaimer: </b><br />This calculator provides estimates based on selected parameters and user adjustments. Actual chargeback figures may vary. We recommend using these estimates for planning and analysis rather than exact financial predictions.
            </Typography>

          </Grid>

        </Grid>
      </Container>
    </ThemeProvider >
  );
}

export default ErrorChargeCalculator;

