import './App.css';
import ErrorChargeCalculator from './ErrorChargeCalculator';

function App() {
  return (
    <div className="App">

      <ErrorChargeCalculator />
    </div>
  );
}

export default App;
