
// src/queries.js
import { gql } from '@apollo/client';

export const GET_DROPDOWN_OPTIONS = gql`
  query GetDropdownOptions ($cuisineGroup: Int = 9) {
    businessTypes
    cuisineGroups {
      _id
      title
      description
      groupId
    }
    cuisineTypes (groupedBy: $cuisineGroup) {
      _id
      title
      description
      groupedBy
    }
    businessClasses
    states
  }
`;



export const GET_INITIAL_VALUES = gql`
  query GetInitialValues($businessType: String, $cuisineType: String, $state: String) {
    analytics(businessType: $businessType, cuisineType: $cuisineType, state: $state) {
      averageNumberOfOrdersPerDay
      averageTicketSize
      chargebackRateOrder
      chargebackRate
    }
  }
`;

export const GET_ANALYTICS_DATA = gql`
  query GetAnalyticsData($businessType: String, $cuisineType: String, $state: String) {
    analytics(businessType: $businessType, cuisineType: $cuisineType, state: $state) {
      averageNumberOfOrdersPerDay
      averageTicketSize
      chargebackRateOrder
      chargebackRate
    }
  }
`;


export const GET_CUISINE_TYPES_BY_GROUP = gql`
query GetCuisineTypesByGroup($groupId: Int) {
  cuisineTypes(groupedBy: $groupId) {
    _id
    title
    description
  }
}
`;